import React, {useEffect, useState} from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Form } from "react-bootstrap"
import instance  from "../../components/api/httpclient"
import { navigate, useIntl, Link } from "gatsby-plugin-intl"

import iosActionIcon from "../../assets/images/ios-action-icon.png";
import iosAddIcon from "../../assets/images/ios-add-icon.png";

const Login = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [unknownAccount, setUnknownAccount] = useState(false);
  const [auth, setAuth] = useState('');
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorLogin, setErrorLogin] = useState(false);
  const [showInstallMessage, setShowInstallMessage] = useState(false);
  const intl = useIntl();

  // Detects if device is on iOS
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test( userAgent );
  }
  // Detects if device is in standalone mode
  const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

  const closeIosPopin = () => {
    setShowInstallMessage(false)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.clear();
    }

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      setShowInstallMessage(true);
    }
  }, []);

  /* Toggle password visibility */
  const togglePwd = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const notValidAuth = () =>  {
    if (auth === 'error') {
      return true;
    }
    return false;
  }

  const isUnknownAccount = () =>  {
    if (unknownAccount === true) {
      return true;
    }
    return false;
  }

  const validateEmail = (email) => {
    var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return re.test(email);
  }

  const handleClick = (event) => {

    event.preventDefault();

    // Hide Login error message
    setErrorLogin(false);

    // Check if email is empty
    if(email === "" || !validateEmail(email)) {
      setErrorEmail(true);
    }

    // Check if email is empty
    if(password === "") {
      setErrorPassword(true);
    }

    if (email !== "" && password !== "") {
      instance.get('login',{
          auth:
          {
            username : email,
            password : password
          }
      }).then(function (response) {
        if(response.status === 200 && response.data.token !== null)
        {
          if (typeof window !== 'undefined') {
            localStorage.setItem('token', 'Bearer ' + response.data.token);
          }

          instance.get("/me",
            {
              headers: {
                'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
              }
            })
            .then( (res) => {
                console.log('response', res)
                if (res.status === 200) {
                  if (typeof window !== 'undefined' ) {
                    localStorage.setItem('currentUser', JSON.stringify(res.data.user));
                  }

                  // If the user already has a car
                  if (res.data.user.cars.length > 0) {

                    // If the user has not selected a dealer
                    if (res.data.user.dealer == null) {

                      navigate('/profile/set-dealer-find');
                    }
                    else {

                        // Dealer
                        instance.get(res.data.user.dealer,
                          {
                            headers: {
                              'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
                            }
                          })
                          .then( (r) => {
                            if (r.status === 200) {
                              if (typeof window !== 'undefined') {
                                localStorage.setItem('currentDealer', JSON.stringify({
                                  id: r.data.id,
                                  name: r.data.name
                                }));


                                const nbCars = res.data.user.cars.length;

                                // Cars
                                for (var i = 0; i < nbCars; i++) {
                                  instance.get(res.data.user.cars[i],
                                    {
                                      headers: {
                                        'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
                                      }
                                    })
                                    .then( (c) => {
                                      if (c.status === 200) {
                                        if (typeof window !== 'undefined') {
                                          instance.get(c.data.carModel,
                                            {
                                              headers: {
                                                'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
                                              }
                                            })
                                            .then( (m) => {
                                              if (m.status === 200) {
                                                if (typeof window !== 'undefined') {

                                                  localStorage.setItem('currentCars', JSON.stringify({
                                                    id: c.data.id,
                                                    licencePlate: c.data.licencePlate,
                                                    model: m.data.name,
                                                    image: m.data.image,
                                                    isLeasePlan: c.data.isLeasePlan
                                                  }));
                                                }
                                              }

                                            }).catch( (error) => {
                                            console.log('error', error)
                                          });


                                        }
                                      }

                                    }).catch( (error) => {
                                    console.log('error', error)
                                  });
                                }
                              }

                              // If the user hasn't finished onboarding
                              if (res.data.user.boardingFinishDate == null) {
                                navigate('/profile/preferences');
                              }
                              else {
                                // Redirect when url has ?redirect params
                                // params has to be lowercase and separated by _
                                // example = sessions_signup redirects to /sessions/signup
                                // example = rewards_about-youplus redirects to /rewards/about-youplus
                                let params = new URLSearchParams(document.location.search.substring(1));
                                if(params.get("redirect") != null) {
                                  let redirect = "/" + params.get("redirect").replace("_", "/"); // Replaces - with /
                                  navigate(redirect)
                                } else {
                                  navigate('/maintenance/timeline');
                                }
                              }
                            }

                          }).catch( (error) => {
                          console.log('error', error)
                        });

                    }
                  }
                  else {
                    navigate('/sessions/onboarding-start');
                  }

                } else if (res.status === 401) {
                  // navigate('/home');
                }
              }
            ).catch( (error) => {
              console.log('error', error);
              setErrorLogin(true);
            }
          );
        }
      }).catch((error) => {
        if (error.toString().includes("404")) {
          setUnknownAccount(true);
          console.log('Email not found')
        }
        else if (error.toString().includes("401")) {
          setAuth("error");
          console.log('Wrong password')
        }

      });
    }


  };

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.sessions.login" })} />

      {showInstallMessage &&
      <div className="ios-pwa-install-popin">
        <div className="close-ios-popin" onClick={() => closeIosPopin()}>x</div>
        <div className="ios-add-icon"><img src={iosAddIcon} /></div> {intl.formatMessage({ id: "ios_popin.install-webapp" })} <img src={iosActionIcon} className="ios-action-icon" />{intl.formatMessage({ id: "ios_popin.add-to-homescreen" })}
      </div>}
      <div className="white-container mb-5">

        <h1 className="with-border-top with-border-bottom text-uppercase">{intl.formatMessage({ id: "1_1_3_signin.title" })}</h1>

        <p className="mb-4">{intl.formatMessage({ id: "1_1_3_signin.error" })}</p>

        <Form onSubmit={(event) => handleClick(event)}>

          <div className="row">

            <div className="col-md-6">

              <Form.Group>
                <Form.Label className="required">{intl.formatMessage({ id: "generic.forms.form_field_labels.email" })}</Form.Label>

                <Form.Control
                  type="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    e.target.value === '' ? setErrorEmail(true) : setErrorEmail(false);
                  }}
                  value={email}
                  className={(errorEmail || errorLogin) ? 'error' : ''}
                />
              </Form.Group>

            </div>

            <div className="col-md-6">

              <Form.Group>
                <Form.Label className="required">{intl.formatMessage({ id: "generic.forms.form_field_labels.password" })}</Form.Label>
                <div className="password-wrapper">
                  {isPasswordVisible ?
                    <input
                      className={(errorPassword || errorLogin) ? "error form-control" : "form-control"}
                      type='text'
                      onChange={
                        (e) => {
                          setPassword(e.target.value);
                          e.target.value === "" ? setErrorPassword(true) : setErrorPassword(false);
                        }
                      }
                      value={password}
                    />
                    :
                    <input
                      className={(errorPassword || errorLogin) ? "error form-control" : "form-control"}
                      type='password'
                      onChange={
                        (e) => {
                          setPassword(e.target.value);
                          e.target.value === "" ? setErrorPassword(true) : setErrorPassword(false);
                        }
                      }
                      value={password}
                    />}

                  <span className={isPasswordVisible ? "icon-eye toggle-pwd grey-border" : "icon-eye toggle-pwd"} onClick={togglePwd.bind()}></span>
                </div>
              </Form.Group>

              <Form.Group>
                <div className="form-text">
                  <Link to="/sessions/password-reset">{intl.formatMessage({ id: "generic.forms.form_buttons.forgot_password" })}</Link>
                </div>
              </Form.Group>

            </div>
            {notValidAuth() &&
              (
                <div className="col-md-12">
                  <Form.Group>
                    <small className="form-text form-validate-error"><span>X</span>{intl.formatMessage({ id: "generic.forms.form_error_message.error_message_5" })}</small>
                  </Form.Group>
                </div>
              )
            }
            {isUnknownAccount() &&
            (
              <div className="col-md-12">
                <Form.Group>
                  <small className="form-text form-validate-error"><span>X</span>{intl.formatMessage({ id: "generic.forms.form_error_message.error_message_10" })}</small>
                </Form.Group>
              </div>
            )
            }
          </div>

          <div className="row">

            <div className="col-md-6">
              <div className="required-field mb-4">*{intl.formatMessage({ id: "generic.forms.form_mentions.required_fields" })}</div>
            </div>
            <div className="col-md-6">
              <button type="submit" className="button red mx-auto mr-md-0">{intl.formatMessage({ id: "generic.forms.form_buttons.signin" })}</button>
            </div>
          </div>
        </Form>
      </div>

      <p className="text-center">{intl.formatMessage({ id: "generic.forms.form_mentions.not_a_member" })}</p>

      <div className="mb-4">
        <Link to="/sessions/signup" className="button white mx-auto">{intl.formatMessage({ id: "generic.forms.form_buttons.signup" })}</Link>
      </div>

    </Layout>
  );
}

export default Login
